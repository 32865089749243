"use client";

import { useSession } from "next-auth/react";
import { useEffect } from "react";

import { type IntercomSettings } from "@/types/intercom";

interface IntercomMessengerProps {
  userHash: string | null;
}

export const IntercomMessenger = ({ userHash }: IntercomMessengerProps) => {
  const { data: session, status } = useSession();

  useEffect(() => {
    if (status === "loading") return;

    const intercomSettings: IntercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "ysj8qv6q",
    };

    if (session?.user?.id) {
      intercomSettings.user_id = session.user.id.toString();
      intercomSettings.user_hash = userHash ? userHash : undefined;
      intercomSettings.name = session.user.fullName;
      intercomSettings.email = session.user.email;
    }

    window.intercomSettings = intercomSettings;

    if (window.Intercom) {
      window.Intercom("reattach_activator");
      window.Intercom("update", intercomSettings);
    } else {
      const intercomScript = document.createElement("script");
      intercomScript.type = "text/javascript";
      intercomScript.async = true;
      intercomScript.src = "https://widget.intercom.io/widget/ysj8qv6q";
      intercomScript.onload = () => window.Intercom("update", intercomSettings);
      document.body.appendChild(intercomScript);
    }
  }, [
    session?.user.id,
    session?.user.fullName,
    session?.user.email,
    status,
    userHash,
  ]);

  return null;
};
